.login-container {
  height: calc(100vh - 328px);
  display: flex;
  align-items: center;
  .form-header {
    position: relative;
  }
  .spinner-border {
    position: absolute;
    top: 0;
    left: 143px;
  }
}

@media (max-width: 567px) {
  .login-container {
    height: 100vh;
    img {
      width: 100%;
      height: auto;
      margin-bottom: 40px;
    }
  }
}
