.quiz-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    .quiz-question {
        margin-top: 60px;
        margin-bottom: 30px;
        font-size: 24px;
    }
}

.quiz-result {
    height: calc(100vh - 375px);
    overflow-y: auto;
}

.quiz-result-answer-correct {
    fill: #198754;
}

@media (max-width: 567px) {
    .quiz-table {
        tr th, td {
            font-size: 14px;
            min-width: 150px;
        }
    }
}