.interview-question {
  &-page {
    .sidebar {
      height: calc(100vh - 370px);
      ul {
        width: 100%;
        li {
          width: 100%;
          .nav-link {
            color: #fff;
          }
          &:hover {
            .nav-link {
              color: #ffc107;
            }
          }
        }
      }
    }
  }
}

// @media (max-width: 820px) {
//   .interview-question {
//     &-container {
//       padding: 0 60px;
//     }
//   }
// }

@media (max-width: 576px) {
  .interview-question {
    &-page {
      .row {
        flex-direction: column;
      }
      .sidebar {
        height: auto;
        ul {
          display: flex;
          flex-direction: row !important;
          flex-wrap: nowrap;
          gap: 50px;
          overflow-x: auto;
          width: auto;
        }
      }
    }
  }
}
