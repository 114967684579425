.footer {
  padding: 30px 0 10px 0;
  display: block;
  position: relative;
  ul {
    padding: 0;
    li {
      list-style: none;
      font-size: 14px;
      display: inline-block;
      a {
        text-decoration: none;
        color: #ffffff;
        &:hover {
          color: #ffc107;
        }
      }
    }
  }
  .copyright-text {
    font-size: 10px;
  }
  &-bottom {
    img {
      margin-left: 2rem !important;
      background: #fff;
      border-radius: 5px;
      padding: 10px;
    }
  }
}

.scroll-to-top {
  z-index: 1;
  color: #dee2e6;
  cursor: pointer;
  i {
    font-size: 25px;
    position: fixed;
    bottom: 10px;
    right: 25px;
    cursor: pointer;

    &:hover {
      color: #ffc107;
    }
  }
}
.footer-new {
  padding: 30px 0 10px 0;
  display: block;
  ul {
    // padding: 0;
    li {
      list-style: none;
      font-size: 14px;
      display: inline-block;
      margin-right: 30px;
      a {
        text-decoration: none;
        color: #ffffff;
        &:hover {
          color: #ffc107;
        }
      }
    }
  }
  .copyright-text {
    font-size: 10px;
    margin-left: 30px;
  }
}
.footer-social {
  display: flex;
  align-items: center;
  ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }

  ul li {
    list-style: none;
    margin: 0;
  }

  ul li a {
    width: 30px;
    height: 30px;
    background-color: #fff;
    text-align: center;
    line-height: 30px;
    font-size: 15px;
    margin: 0 10px;
    display: block;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    border: 3px solid #fff;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul li a .icon {
    position: relative;
    color: #262626;
    transition: 0.5s;
    z-index: 3;
  }

  ul li a:hover .icon {
    color: #fff;
    transform: rotateY(360deg);
  }

  ul li a:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f00;
    transition: 0.5s;
    z-index: 2;
  }

  ul li a:hover:before {
    top: 0;
  }

  ul li:nth-child(1) a:before {
    background: #3b5999;
  }

  ul li:nth-child(2) a:before {
    background: #cd201f;
  }

  ul li:nth-child(3) a:before {
    background: #f09433;
    background: -moz-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: -webkit-linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    background: linear-gradient(
      45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }

  ul li:nth-child(4) a:before {
    background: #dd4b39;
  }
}
@media (max-width: 567px) {
  .footer {
    ul li {
      text-align: center;
      display: block;
      margin: 0 0 15px 0;
      font-size: 12px;
    }
  }

  .scroll-to-top {
    bottom: 5px;
    right: 20px;
  }
}
