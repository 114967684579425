.related-blog-sidebar {
  .course-card {
    width: 100% !important;
  }
}

.blog-post {
  &-content {
    padding-right: 120px;
    @media (max-width: 586px) {
      padding: 0 1.5rem;
    }
  }
  .blog-header-img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }
}

@media (min-width: 576px) and (max-width: 820px) {
  .related-blog-sidebar {
    display: flex;
    flex-wrap: wrap;
    &-card {
      padding-right: 20px;
    }
  }
  .blog-post .blog-post-content {
    padding-right: 0 !important;
    padding: 1.5 rem !important;
  }
}
