@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import "./home";
@import "./course-detail";
@import "./quiz-view";
@import "./interview-question";
@import "./login";
@import "./footer";
@import "./blog";

p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
}

* {
  font-family: "Poppins", sans-serif;
}

.title {
  font-size: 32px;
}

.sub-title {
  font-size: 21px;
}
