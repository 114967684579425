.primary-menu {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  .navbar-nav {
    .nav-link .dropdown-item {
      font-size: 14px;
    }

    a:hover,
    .dropdown-menu li:hover,
    .nav-link.active {
      color: #5e2391;
    }
    .top-menu-btn a:hover {
      color: #fff !important;
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: #fff !important;
    }
  }

  .top-bar-user {
    button {
      text-align: left;
    }
    &:hover {
      background-color: #f8f9fa;
      border-radius: 10px;
    }
  }
}

.course-card {
  border: 1px solid #dee2e6;
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  img {
    height: 200px;
    object-fit: cover;
  }
  .react-player {
    height: 200px !important;
    object-fit: cover;
    width: 100% !important;
  }
  .card-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .card-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .card-body {
    height: 90px;
    padding: 0 10px;
  }
  .card-footer {
    padding: 0 10px;
  }
}
// #5e2391
// .pagination>li.active>a {
//   background-color:  #198754 !important;
//   border-color:  #198754 !important;
//   color: #5A4181;
// }
.header-btn {
  background-color: #c00384;
  &:hover {
    background-color: #b62b8a !important;
    border-radius: 10px;
  }
}
.pagination > li > a {
  background-color: white;
  color: #198754;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination > .active > a {
  color: white;
  background-color: #198754 !important;
  border: solid 1px #198754 !important;
}

.pagination > .active > a:hover {
  background-color: #198754 !important;
  border: solid 1px #198754;
}
